.select-bar {
    --gap: 20px;

    align-items: flex-start;
    display: flex;
    gap: var(--gap);
    justify-content: space-between;
}

.select-bar-left,
.select-bar-right {
    align-items: center;
    display: flex;
    gap: var(--gap);
    margin-block: var(--gap);
}

.select-bar-right {
    flex-shrink: 0;
    min-width: 240px;
}

.active,
.active i {
    color: var(--teal);
}

.grid,
.grid-small,
.list {
    display: grid;
}

.grid,
.grid-small {
    gap: 20px;
    grid-template-columns: repeat(1, minmax(0, 1fr));
    row-gap: 30px;
}

.list {
    gap: 20px;
    grid-template-columns: repeat(1, minmax(0, 1fr));
}

@media screen and (min-width: 781px) {
    .grid {
        grid-template-columns: repeat(2, minmax(0, 1fr));
    }
}

/* @media screen and (min-width: 981px) {
    .grid {
        grid-template-columns: repeat(3, minmax(0, 1fr));
    }
} */

@media screen and (min-width: 1112px) {
    .grid-small {
        grid-template-columns: repeat(5, minmax(0, 1fr));
    }
}

@media screen and (max-width: 1460px) {
    .list {
        grid-template-columns: repeat(2, minmax(0, 1fr)) !important;
    }
}